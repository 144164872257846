import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './docs.module.scss';

const cx = classNames.bind(styles);

class TermsOfUse extends Component {

  constructor(props) {
    super(props);
    this.state = {
      frameHeight: 0,
      loadDone: false,
    };
  }

  componentDidMount() {
    this._onLoad();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { loadDone } = nextState;
    return loadDone;
  }

  render() {
    const { frameHeight } = this.state;

    return (
      <Container className={cx('container')}>
        <Row>
          <Col xs={12} lg={10} offset={{ lg: 1 }}>
            <iframe
              title="클럽아티제이용약관"
              src="/static/pages/terms-of-use.html"
              scrolling="no"
              frameBorder="0"
              width="100%"
              height={`${frameHeight}px`}
              onLoad={this._onLoad}
            />
          </Col>
        </Row>
      </Container>
    );
  }

  _onLoad = () => {
    const obj = ReactDOM.findDOMNode(this);
    const iframe = obj.childNodes[0].childNodes[0].childNodes[0];
    const { body } = iframe.contentWindow.document;

    if (body) {
      this.setState({
        frameHeight: body.scrollHeight,
        loadDone: true,
      });
    }
  }
}

export default TermsOfUse;
